<!-- @file 数据面板 -->
<template>
  <div>
    <div class="l-mr-10 l-d-inline-block">
      <l-select v-model="type" @change="changeLangOrType">
        <l-select-option
          v-for="item in typeList"
          :key="item.value"
          :value="item.value"
        >
          {{ item.name }}
        </l-select-option>
      </l-select>
    </div>
    <l-select v-if="type != 'wx'" v-model="lang" @change="changeLangOrType">
      <l-select-option
        v-for="item in languageList"
        :key="item.value"
        :value="item.value"
      >
        {{ item.name }}
      </l-select-option>
    </l-select>
    <div class="p-data_statistics-total">
      <div class="p-data_statistics-total_item">
        <div>累计访问人数</div>
        <div class="p-data_statistics-total_item_num">{{ visitorSummary.visitors }}</div>
      </div>
      <div class="p-data_statistics-total_item">
        <div>总注册人数</div>
        <div class="p-data_statistics-total_item_num">{{ visitorSummary.registered }}</div>
      </div>
    </div>
    <div>
      <l-button class="l-mr-20" :type="selected == 0 ? 'primary':'default'" @click="quickSelect(0)">今天</l-button>
      <l-button class="l-mr-20" :type="selected == 1 ? 'primary':'default'" @click="quickSelect(1)">昨天</l-button>
      <l-button class="l-mr-20" :type="selected == 2 ? 'primary':'default'" @click="quickSelect(2)">最近7天</l-button>
      <l-button class="l-mr-20" :type="selected == 3 ? 'primary':'default'" @click="quickSelect(3)">最近30天</l-button>
      <span class="l-mr-20">自定义</span>
      <l-range-picker @change="changeDate" />
      <l-button class="l-float-right" @click="exportData">导出数据</l-button>
    </div>

    <l-table-common class="l-mt-20" :data-source="tableData">
      <l-table-column
        data-index="date"
        title="日期"
      />
      <l-table-column
        data-index="visitors"
        title="访问人数"
      />
      <l-table-column
        data-index="registered"
        title="注册人数"
      />
    </l-table-common>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  data() {
    return {
      type: 'h5',
      typeList: [{
        name: 'H5',
        value: 'h5'
      }, {
        name: '微信',
        value: 'wx'
      }],
      lang: 'zh-CN',
      languageList: [{
        name: '简体中文',
        value: 'zh-CN'
      }, {
        name: '繁体中文',
        value: 'zh-TW'
      }, {
        name: 'English',
        value: 'en'
      }],
      selected: 0,
      startDay: '',
      endDay: '',
      tableData: {
        isLoading: false,
        total: 0,
        page: 1,
        size: 30,
        list: []
      }
    }
  },

  computed: {
    ...mapState({
      visitorSummary: state => state.statistics.visitorSummary,
      visitorSummaryDate: state => state.statistics.visitorSummaryDate
    })
  },

  mounted() {
    this.startDay = new Date(this.$utils.formatDate(new Date().getTime(), 'yyyy/MM/dd 00:00:00')).getTime()
    this.endDay = new Date(this.$utils.formatDate(new Date().getTime(), 'yyyy/MM/dd 23:59:59')).getTime()
    this.getSummary()
    this.getList()
  },

  methods: {
    changeLangOrType() {
      this.getSummary()
      this.getList(1)
    },
    quickSelect(type) {
      this.selected = type
      if (type === 0) {
        this.startDay = new Date(this.$utils.formatDate(new Date().getTime(), 'yyyy/MM/dd 00:00:00')).getTime()
        this.endDay = new Date(this.$utils.formatDate(new Date().getTime(), 'yyyy/MM/dd 23:59:59')).getTime()
      } else if (type === 1) {
        this.startDay = new Date(this.$utils.formatDate(new Date().getTime(), 'yyyy/MM/dd 00:00:00')).getTime() - 86400000
        this.endDay = new Date(this.$utils.formatDate(new Date().getTime(), 'yyyy/MM/dd 23:59:59')).getTime() - 86400000
      } else if (type === 2) {
        this.startDay = new Date(this.$utils.formatDate(new Date().getTime(), 'yyyy/MM/dd 00:00:00')).getTime() - 86400000 * 6
        this.endDay = new Date(this.$utils.formatDate(new Date().getTime(), 'yyyy/MM/dd 23:59:59')).getTime()
      } else if (type === 3) {
        this.startDay = new Date(this.$utils.formatDate(new Date().getTime(), 'yyyy/MM/dd 00:00:00')).getTime() - 86400000 * 29
        this.endDay = new Date(this.$utils.formatDate(new Date().getTime(), 'yyyy/MM/dd 23:59:59')).getTime()
      }
      this.getList(1)
    },
    async getSummary(startDay, endDay) {
      await this.$store.dispatch('statistics/visitorRegisterSummary', {
        language: this.lang,
        startDay,
        endDay,
        type: this.type
      })
    },
    async getList(page) {
      page = (page && page.current) || page || this.tableData.page || 1
      this.tableData.isLoading = true
      try {
        const data = await this.$store.dispatch('statistics/visitorRegisterList', {
          language: this.lang,
          page: page,
          size: this.tableData.size,
          startDay: this.startDay,
          endDay: this.endDay,
          type: this.type
        })
        this.tableData.isLoading = false
        this.tableData.list = data.list || []
        this.tableData.total = data.count
        this.tableData.page = page
        await this.getSummary(this.startDay, this.endDay)
        this.tableData.list.push({
          date: '总计',
          registered: this.visitorSummaryDate.registered,
          visitors: this.visitorSummaryDate.visitors
        })
      } catch (e) {
        this.tableData = this.$options.data().tableData
      }
    },
    changeDate(e) {
      this.startDay = new Date(this.$utils.formatDate(new Date(e[0]).getTime(), 'yyyy/MM/dd 00:00:00')).getTime()
      this.endDay = new Date(this.$utils.formatDate(new Date(e[1]).getTime(), 'yyyy/MM/dd 23:59:59')).getTime()
      this.getList(1)
    },
    // 导出数据
    exportData() {
      const url = `/api/admin/instance/statistic/visitor-registered-export?language=${this.lang}&startDay=${this.startDay}&endDay=${this.endDay}`
      this.$store.dispatch('download', { url })
    }
  }
}
</script>

<style lang="scss">
.p-data_statistics-total {
  display: flex;
  margin: 20px 0;
  .p-data_statistics-total_item {
    margin-right: 20px;
    border: 1px solid #1890ff;
    border-radius: 8px;
    padding: 20px;
    width: 200px;
    font-size: 16px;
    box-shadow: 0 0 5px #a6d4ff;
    .p-data_statistics-total_item_num {
      font-size: 24px;
      font-weight: bold;
    }
  }
}
</style>
